import auth from "../../service/UserPool";
import "./headerstyles.css";

function Header({ setsearchBar, loggedIn, searchBar }) {
  function giveInput(event) {
    setsearchBar(event.target.value);
  }

  const onClickBtn = (e) => {
    e.preventDefault();
    sessionStorage.setItem("searchBar", searchBar);
    window.location = "/";
  };

  return (
    <header>
      <a href="/">
        <img src="../logo.svg" alt="Logo Quack Overflow" />
      </a>

      {loggedIn && (
        <form>
          <input
            type="text"
            placeholder="Find a duck..."
            onChange={giveInput}
            value={searchBar}
          />
          <button onClick={onClickBtn}>Search</button>
        </form>
      )}
      <div className="dropdown">
        <button onClick={onClickBtn} className="dropbtn">
          <b>My Quacks</b>
        </button>
        {loggedIn ? (
          <div className="dropdown-content">
            <a href="/" onClick={() => auth.logout()}>
              Log Out
            </a>
          </div>
        ) : (
          <div className="dropdown-content">
            <a href="/login">Log in</a>
            <a href="/register">Register</a>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;

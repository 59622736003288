import "./productdeets.css";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../service/api";
import { ReactComponent as Spinner } from "../../loadingspinner.svg";

export default function Duck() {
  const params = useParams();
  const [duck, setDuck] = useState({
    img: "",
    price: "",
    name: "",
    type: "",
    description: "",
    duckId: "",
    color: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get("/duck/" + params.id).then((d) => {
      setDuck({
        ...d,
        img: `https://${process.env.REACT_APP_CFCDN}${d.img}`,
      });
      setLoading(false);
    });
  }, [params.id, setDuck]);

  const onClickBtn = async (e) => {
    e.target.innerText = "Added to cart";
    e.target.disabled = true;
    await api.post("/cart/add", { duckId: duck.duckId });
    e.target.innerText = "Add to cart";
    e.target.disabled = false;
  };

  return (
    <main>
      {loading ? (
        <Spinner className="spinner" />
      ) : (
        <div
          className="ProductDetail"
          style={{ display: !duck.name && "none" }}
        >
          <div className="image">
            <img src={duck.img} alt={duck.name} />
          </div>
          <div className="deets">
            <h2>{duck.name}</h2>
            <p>
              <b>Type: </b>
              {duck.type}
            </p>
            <p>
              <b>Color: </b>
              {duck.color}
            </p>
            <p>
              <b>Price: </b>${duck.price}
            </p>
            <p>
              <b>Description: </b>
              {duck.description}
            </p>
            <button onClick={onClickBtn}>Add to Cart</button>
          </div>
        </div>
      )}
    </main>
  );
}

import { useState } from 'react';
import auth from '../../service/UserPool';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        await auth.login(email, password)
        window.location = "/"
    } catch (err) {
        console.error(err)
        alert(err)
    }
  };
  return (
    <form className='auth' style={{display: 'block', width: "30%", margin: "auto", textAlign: "center"}} onSubmit={handleSubmit}>
        <h3 style={{textAlign: "center", marginBottom: "20px"}}>Log in</h3>
      <label >
       <b>Email</b>
        </label>
        <br/>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <br/>
      <label>
        <b>Password</b>
        </label>
        <br/>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <br/>
      <button type="submit">Login</button>
    </form>
  );
}
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

export default class CognitoAuth extends CognitoUserPool {
  async isLoggedIn() {
    const session = await this.getSession();
    return session && session.isValid();
  }

  getCurrentUser(Username) {
    return Username
      ? new CognitoUser({ Username, Pool: this })
      : super.getCurrentUser();
  }

  async getTokens(username) {
    const session = await this.getSession(username);
    return {
      idToken: session.getIdToken().jwtToken,
      accessToken: session.getAccessToken().jwtToken,
    };
  }

  getSession(username) {
    const cognitoUser = this.getCurrentUser(username);
    if (cognitoUser != null)
      return new Promise((resolve, reject) => {
        cognitoUser.getSession((err, sess) => {
          if (err) reject(err);
          else resolve(sess);
        });
      });
  }

  logout(username) {
    return this.getCurrentUser(username).signOut();
  }

  login(Username, Password) {
    const user = this.getCurrentUser(Username);

    const authDetails = new AuthenticationDetails({ Username, Password });

    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
        newPasswordRequired: (data) => reject(data),
      });
    });
  }

  register(username, password, attributes) {
    const attributeList = Object.entries(attributes).map(
      ([Name, Value]) => new CognitoUserAttribute({ Name, Value })
    );
    const self = this;

    return new Promise((resolve, reject) => {
      self.signUp(username, password, attributeList, null, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  confirmUser(username, code) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.confirmRegistration(code, true, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  getUserAttributes(username) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, result) => {
        if (err) reject(err);
        else
          resolve(
            result.map((attr) => ({ [attr.getName()]: attr.getValue() }))
          );
      });
    });
  }

  getAttributeVerificationCode(username, attributeName) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.getAttributeVerificationCode(attributeName, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
        inputVerificationCode: null,
      });
    });
  }

  deleteUserAttributes(username, attributes) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.deleteAttributes(attributes, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  updateUserAttributes(username, attributes) {
    const user = this.getCurrentUser(username);
    const attributeList = Object.entries(attributes).map(
      ([Name, Value]) => new CognitoUserAttribute({ Name, Value })
    );

    return new Promise((resolve, reject) => {
      user.updateAttributes(attributeList, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  changePassword(username, oldPassword, newPassword) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  forgotPassword(username) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  }

  confirmPassword(username, code, newPassword) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.confirmPassword(code, newPassword, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  }

  deleteUser(username) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.deleteUser((err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  listDevices(username) {
    const user = this.getCurrentUser(username);
    return new Promise((resolve, reject) => {
      user.listDevices({
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  }

  getDevice(username) {
    const user = this.getCurrentUser(username);
    return new Promise((resolve, reject) => {
      user.getDevice({
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  }

  rememberDevice(username, remember) {
    const user = this.getCurrentUser(username);
    return new Promise((resolve, reject) => {
      if (remember)
        user.setDeviceStatusRemembered({
          onSuccess: (result) => resolve(result),
          onFailure: (err) => reject(err),
        });
      else
        user.setDeviceStatusNotRemembered({
          onSuccess: (result) => resolve(result),
          onFailure: (err) => reject(err),
        });
    });
  }

  forgetDevice(username) {
    const user = this.getCurrentUser(username);
    return new Promise((resolve, reject) => {
      user.forgetDevice({
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  }

  async refreshSession(username) {
    const user = this.getCurrentUser(username);
    const session = await this.getSession(username);
    return await new Promise((resolve, reject) => {
      user.refreshSession(session.getRefreshToken(), (err, sess) => {
        if (err) reject(err);
        else resolve(sess);
      });
    });
  }

  setMfaPreference(username, smsSettings, totpSettings) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.setMfaPreference(smsSettings, totpSettings, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  authenticateUser(Username, Password) {
    const user = this.getCurrentUser(Username);

    const authDetails = new AuthenticationDetails({ Username, Password });

    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: (result) => resolve({ type: "success", data: result }),
        onFailure: (err) => reject(err),
        newPasswordRequired: (data) =>
          resolve({ type: "newPasswordRequired", data }),
        mfaSetup: (challengeName, challengeParameters) =>
          resolve({
            type: "mfaSetup",
            data: { challengeName, challengeParameters },
          }),
        associateSecretCode: (secretCode) =>
          resolve({ type: "associateSecretCode", data: secretCode }),
        selectMFAType: (challengeName, challengeParameters) =>
          resolve({
            type: "mfaSetup",
            data: { challengeName, challengeParameters },
          }),
        totpRequired: (secretCode) =>
          resolve({ type: "totpRequired", data: secretCode }),
        mfaRequired: (codeDeliveryDetails) =>
          resolve({ type: "mfaRequired", data: codeDeliveryDetails }),
      });
    });
  }

  resendConfirmationCode(username) {
    const user = this.getCurrentUser(username);

    return new Promise((resolve, reject) => {
      user.resendConfirmationCode((err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }
}

import React, { useState } from "react";
import auth from "../../service/UserPool";

export default function VerifyOTP() {
  const [otp, setOtp] = useState("");

  const performVerify = async (event) => {
    event.preventDefault();
    try {
      const email = sessionStorage.getItem("regEmail");
      await auth.confirmUser(email, otp);
      sessionStorage.removeItem("regEmail");
      window.location = "/";
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const resendCode = async (event) => {
    event.preventDefault();
    try {
      const email = sessionStorage.getItem("regEmail");
      await auth.resendConfirmationCode(email);
      alert("Resent confirmation code");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <div>
      <h3>Please confirm your email using the code sent to you</h3>
      <input
        style={{ width: "100%" }}
        type="text"
        name="otp"
        value={otp}
        placeholder="Enter Verification Code"
        onChange={(e) => setOtp(e.target.value)}
      />
      <br />
      <button type="submit" onClick={performVerify}>
        Submit
      </button>
      <button type="button" onClick={resendCode}>
        Resend Verification Code
      </button>
    </div>
  );
}

import React from "react";
import { DivCard, DivContainer, DivContainerImg, DivInfo } from "./styleCard";
import { Link } from "react-router-dom";

export default function Card({ addToCart, ducks, searchBar }) {
  return (
    <DivContainer>
      {(searchBar.length === 0
        ? ducks
        : ducks.filter(({ name }) =>
            name.toLowerCase().includes(searchBar.toLowerCase())
          )
      ).map((duck) => (
        <DivCard key={duck.id}>
          <Link to={`/duck/${duck.duckId}`}>
            <DivContainerImg>
              <img src={duck.img} alt="imagem" />
            </DivContainerImg>
          </Link>
          <DivInfo>
            <h3>{duck.name}</h3>
            <span>{duck.type}</span>
            <p>${duck.price}</p>
            <button onClick={(e) => addToCart(e, duck)}>Add to Cart</button>
          </DivInfo>
        </DivCard>
      ))}
    </DivContainer>
  );
}

import { useState, useEffect } from "react";

import Cart from "./components/Cart/cart";
import Card from "./components/Card/card";
import api from "./service/api";
import { ReactComponent as Spinner } from "./loadingspinner.svg";

export default function MainContent({ searchBar }) {
  const [cartItems, setCartItems] = useState([]);
  const [Ducks, setDucks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const ducks = await api.get("/ducks");
      setDucks(
        ducks.map((x) => {
          x.img = `https://${process.env.REACT_APP_CFCDN}${x.img}`;
          return x;
        })
      );

      const cart = await api.get("/cart");
      setCartItems(
        cart.map((cartItem) => ({
          quantity: cartItem.quantity,
          ...cartItem.duck,
          img: `https://${process.env.REACT_APP_CFCDN}${cartItem.duck.img}`,
          duck: undefined,
          userId: undefined,
        }))
      );

      setLoading(false);
    })();
  }, []);

  async function addToCart(e, { duckId }) {
    e.target.innerText = "Added to Cart";
    e.target.disabled = true;
    const cart = await api.post("/cart/add", { duckId });
    setCartItems(
      cart.map((cartItem) => ({
        quantity: cartItem.quantity,
        ...cartItem.duck,
        img: `https://${process.env.REACT_APP_CFCDN}${cartItem.duck.img}`,
        duck: undefined,
        userId: undefined,
      }))
    );
    e.target.innerText = "Add to Cart";
    e.target.disabled = false;
  }
  async function deleteItemFromCart({ duckId }) {
    const cart = await api.post("/cart/remove", { duckId });
    setCartItems(
      cart.map((cartItem) => ({
        quantity: cartItem.quantity,
        ...cartItem.duck,
        img: `https://${process.env.REACT_APP_CFCDN}${cartItem.duck.img}`,
        duck: undefined,
        userId: undefined,
      }))
    );
  }

  async function deleteAllItems() {
    setCartItems(await api.post("/cart/clear"));
  }

  return (
    <main>
      {loading ? (
        <Spinner className="spinner" />
      ) : searchBar.length !== 0 ? (
        <>
          <h2 className="h2">
            Results for:<p className="p">{searchBar}</p>
          </h2>
          <div className=" containerGeral">
            <Card addToCart={addToCart} ducks={Ducks} searchBar={searchBar} />
            <Cart
              deleteItemFromCart={deleteItemFromCart}
              cartItems={cartItems}
              deleteAllItems={deleteAllItems}
            />
          </div>
        </>
      ) : (
        <div className=" containerGeral">
          <Card addToCart={addToCart} ducks={Ducks} searchBar={searchBar} />
          <Cart
            deleteItemFromCart={deleteItemFromCart}
            cartItems={cartItems}
            deleteAllItems={deleteAllItems}
          />
        </div>
      )}
    </main>
  );
}

import auth from "./UserPool"
import axios from "axios"

async function connectApi(endpoint, method = "get", data) {
    try {
      const { idToken } = await auth.getTokens()
      const res = await axios({
        url: `https://${process.env.REACT_APP_API}${endpoint}`,
        method,
        headers: {
          "Content-Type": "application/json",
          "Accept": "*/*",
          Authorization: `Bearer ${idToken}`, 
         },
        data: data || {},
        withCredentials: true
      });
      return res.data;
    } catch (err) {
      console.log(
        `Had Issues ${method}ing the route, ${endpoint}, with data: ${JSON.stringify(
          data
        )}`
      );
      console.error(err.response);
      if (err.response && err.response.status === 401) {
        auth.logout();
        window.location.assign("/login");
        return;
      }
      throw err.response;
    }
  }

const methods = {
  get(endpoint, data) {
    return connectApi(endpoint, "GET", data);
  },
  post(endpoint, data) {
    return connectApi(endpoint, "POST", data);
  },
  delete(endpoint, data) {
    return connectApi(endpoint, "DELETE", data);
  }
};

export default methods;
import { Link } from "react-router-dom";
import {
  Aside,
  DivCardCarrinho,
  DivNomeCarrinho,
  DivSacolaVazia,
  Sectionn,
} from "./styleCart";
import { useEffect, useState } from "react";

function Cart({ deleteItemFromCart, cartItems, deleteAllItems }) {

  const [total, setTotal] = useState(0);

  useEffect(() => {

    setTotal(cartItems.reduce((red, { price, quantity }) => (
      red + price * quantity
      ), 0));

  }, [cartItems]);

  return (
    <Aside>
      <DivNomeCarrinho>
        <h3>Cart</h3>
      </DivNomeCarrinho>
      <Sectionn>
        {cartItems.length === 0 ? (
          <DivSacolaVazia>
            <h3>Your cart is empty</h3>
            <span>Add items</span>
          </DivSacolaVazia>
        ) : (
          cartItems.map((item) => (
            <DivCardCarrinho>
              <Link to={`/duck/${item.duckId}`}>
              <div className="imgCart">
                <img src={item.img} alt="duck" />
              </div>
              </Link>
              <div>
                <h4>{item.name} { item.quantity > 1 && `(×${item.quantity})`}</h4>
                <p>{item.type}</p>
              </div>
              <button
                className="btnRemoverCardCarrinho"
                onClick={() => deleteItemFromCart(item)}
              >
                Remove
              </button>
            </DivCardCarrinho>
          ))
        )}
      </Sectionn>
      {cartItems.length !== 0 && (
        <div>
          <div className="divTotal">
            <span>Total</span>
            <p>$ {total.toFixed(2)}</p>
          </div>
          <button onClick={() => deleteAllItems()}>Remove all</button>
        </div>
      )}
    </Aside>
  );
}

export default Cart;

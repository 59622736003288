import auth from '../../service/UserPool';
import { useState } from 'react';

export default function RegisterForm () {
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    given_name: '',
    family_name: '',
    phone_number: '',
  });

  const [passwordsMatch, setpasswordsMatch] = useState(true)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    
    const {
        email,
        password,
        confirmPassword,
        given_name,
        family_name,
        phone_number
    } = formState

    if (password !== confirmPassword) {
        setpasswordsMatch(false)
    } else {
        try {
          const data = await auth.register(email, password, {email, given_name, family_name, phone_number})
          sessionStorage.setItem('regEmail', data.user.username)
          window.location = "/verify"
        } catch (err) {
          console.error(err)
          alert(err.message)
        }
    }

  };
  return (
    <form className='auth' style={{display: 'block', width: "30%", margin: "auto"}} onSubmit={handleSubmit}>
      <h3 style={{textAlign: "center"}}>Sign up</h3>
      <label>
        Email:
        <input type="email" name="email" value={formState.email} onChange={handleChange} />
      </label>
      <br />
      <label>
        Password:
        <input type="password" name="password" value={formState.password} onChange={handleChange} />
      </label>
      <br />
      <label>
        Confirm Password:
        <input
          type="password"
          name="confirmPassword"
          value={formState.confirmPassword}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        First Name:
        <input type="text" name="given_name" value={formState.given_name} onChange={handleChange} />
      </label>
      <br />
      <label>
        Last Name:
        <input type="text" name="family_name" value={formState.family_name} onChange={handleChange} />
      </label>
      <br />
      <label>
        Phone Number:
        <input type="tel" name="phone_number" value={formState.phone_number} onChange={handleChange} />
      </label>
      <br />
      <button type="submit">Register</button>
      <p style={{color: "red"}}>{!passwordsMatch && "Passwords do not match"}</p>
    </form>
  );
}

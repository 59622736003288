import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import MainContent from "./MainContent";
import Header from "./components/Header/header";
import RegisterForm from "./components/Authentication/Register";
import LoginForm from "./components/Authentication/Login";
import auth from "./service/UserPool";
import Duck from "./components/Product/Duck";
import VerifyOTP from "./components/Authentication/VerifyOTP";

function App() {
  const [searchBar, setsearchBar] = useState("");
  const [loggedIn, setLoggedIn] = useState(null);

  useEffect(() => {
    auth.isLoggedIn().then((isLoggenIn) => setLoggedIn(isLoggenIn));
    const search = sessionStorage.getItem("searchBar");
    if (search) {
      setsearchBar(search);
      sessionStorage.removeItem("searchBar");
    }
  }, []);

  return (
    <div className="App">
      <Header
        setsearchBar={setsearchBar}
        loggedIn={loggedIn}
        searchBar={searchBar}
      />
      <Router>
        <Routes>
          {loggedIn !== null && (
            <Route
              path="/"
              Component={() =>
                loggedIn ? <MainContent searchBar={searchBar} /> : <LoginForm />
              }
            />
          )}
          <Route path="/register" Component={RegisterForm} />
          <Route path="/login" Component={LoginForm} />
          <Route path="/duck/:id" Component={Duck} />
          <Route path="/verify" Component={VerifyOTP} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
